import * as React from "react"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Select from "react-select"

import styled from "@emotion/styled"

const IndexPage = () => {
  const allPagesResult = useStaticQuery(graphql`
    {
      allSitePage {
        edges {
          node {
            path
            pageContext
          }
        }
      }
    }
  `).allSitePage.edges

  const dropdownOptions = allPagesResult.map(page => {
    return {
      value: page.node.path,
      label: page.node.pageContext.cityName,
    }
  })

  const handleSelect = e => {
    console.log(e)
    navigate(e.value)
  }

  return (
    <Layout>
      <Seo title="Home" />
      <h1>Välkomen till tider.nu</h1>
      <p>Har kan du se tider för nästan alla städer.</p>
      <p>Vi har också tips om vad du kan göra i varje stad!</p>
      <StyledSelect
        placeholder="Hitta stad"
        ariaLabel="Hitta stad"
        onChange={handleSelect}
        options={dropdownOptions}
      />
      <br />
      <br />
      <p>Mest populära städerna:</p>
      {allPagesResult.slice(0, 20).map(page => {
        const { path, pageContext } = page.node
        const { cityName } = pageContext

        return (
          <div key={path}>
            <Link to={path}>{cityName}</Link>
          </div>
        )
      })}
    </Layout>
  )
}

const StyledSelect = styled(Select)`
  max-width: 32rem;
`

export default IndexPage
